import {
  cilArrowRight,
  cilSettings,
  cilUser,
  cilImage,
  cilDevices,
  cilChart,
  cilContact,
  cilHistory,
  cilRouter,
  cilCalendar,
  cilBadge,
  cilDescription,
  cilLockLocked,
  cilAccountLogout,
  cilCarAlt,
  cilDelete,
} from "@coreui/icons";

import {
  logoFull,
  main,
  users,
  events,
  status,
  contacts,
  atrributes,
  source,
  log,
  devices,
  lock,
  logOut,
  calendar,
  person,
  monitor,
  disk,
  figures,
  check,
  tableLog,
  tableSource,
  tableQr,
  tableAchiments,
  tableEdit,
  imageUploader,
  arrowLeft,
  logoMinimize,
  close,
  settings,
  moreSettings,
  plus,
  tavridaFullLogo,
  tavridaMinLogo,
  logIn,
  dots,
  doc,
  exclamation,
  check2,
  fast,
  deleteFile,
  download,
  check3,
  deny,
  eye,
  plus2,
  edit,
  deleteItem,
  car,
  eraserIcon,
  copy
} from "./sprite";

export const iconsSet = Object.assign(
  {},
  {
    logoFull,
    main,
    users,
    events,
    status,
    contacts,
    atrributes,
    source,
    log,
    devices,
    lock,
    logOut,
    calendar,
    person,
    monitor,
    disk,
    figures,
    check,
    tableLog,
    tableSource,
    tableQr,
    tableAchiments,
    tableEdit,
    imageUploader,
    arrowLeft,
    logoMinimize,
    close,
    settings,
    moreSettings,
    plus,
    logIn,
    dots,
    doc,
    exclamation,
    check2,
    fast,
    deleteFile,
    download,
    check3,
    deny,
    eye,
    plus2,
    edit,
    deleteItem,
    car,
    eraserIcon,
    copy,
    tavridaFullLogo,
    tavridaMinLogo,
    cilHistory,
    cilContact,
    cilChart,
    cilDevices,
    cilArrowRight,
    cilSettings,
    cilUser,
    cilImage,
    cilRouter,
    cilCalendar,
    cilBadge,
    cilDescription,
    cilLockLocked,
    cilAccountLogout,
    cilCarAlt,
    cilDelete,
  }
);
